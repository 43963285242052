import React from "react";
import { Link } from "gatsby";

export const Header = ({ siteTitle, siteDescription }) => (
  <div className="header">
    <Link to="/">
      <div className="logo">IGOR</div>
    </Link>
    <div className="navigation">
      <ul>
        <li>
          <Link to="/" activeStyle={{ fontWeight: 1000 }}>
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/blog"
            activeStyle={{ fontWeight: 1000 }}
            partiallyActive={true}
          >
            Blog
          </Link>
        </li>
        <li>
          <Link
            to="/gallery"
            activeStyle={{ fontWeight: 1000 }}
            partiallyActive={true}
          >
            Gallery
          </Link>
        </li>
        <li>
          <Link
            to="/projects"
            activeStyle={{ fontWeight: 1000 }}
            partiallyActive={true}
          >
            Projects
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            activeStyle={{ fontWeight: 1000 }}
            partiallyActive={true}
          >
            Contact
          </Link>
        </li>
      </ul>
    </div>
  </div>
);
