import React from "react";
import "../../static/main.css";
import { Header } from "./Header";

export const Layout = ({ children }) => (
  <div className="home">
    <div className="wrapper">
      <Header />
      {children}
    </div>
  </div>
);
